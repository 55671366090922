import {getImageUrl} from "../../utils/urls";

const title = "FREQUENTLY ASKED QUESTIONS";
const desc = "To better serve your needs we've compiled a set of frequently asked questions.";


const Faq = () => {

    return (
        <section className="faq padding-top padding-bottom">
            <div className="container">
                <div className="row justify-content-center flex-row-reverse">
                    <div className="col-lg-6 col-12">
                        <div className="faq-right-part">
                            <div className="faq-thumb">
                                <img src={getImageUrl("assets/images/faq/bellaCom.png")} alt="faq-thumb" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="faq-left-part">
                            <div className="section-header text-start">
                                <h2>{title}</h2>
                                <p>{desc}</p>
                            </div>
                            <div className="section-wrapper">
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <div className="accordion-header" id="accOne">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collOne" aria-expanded="true" aria-controls="collOne">
                                                <div className="accordion-list">
                                                    <div className="left">
                                                        <div className="icon"></div>
                                                    </div>
                                                    <div className="right">
                                                        <h6>01.	What is this game?</h6>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                        <div id="collOne" className="accordion-collapse collapse show" aria-labelledby="accOne" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <div className="accordion-answer">
                                                    <p>Vegas Craze Slots provides our players with the best online casino experience. Play on a vast variety of unique slot games and enjoy a true Vegas experience from the comfort of your homes!
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div className="accordion-header" id="accTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collTwo" aria-expanded="true" aria-controls="collTwo">
                                                <div className="accordion-list">
                                                    <div className="left">
                                                        <div className="icon"></div>
                                                    </div>
                                                    <div className="right">
                                                        <h6>02. How do I become a VIP?</h6>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                        <div id="collTwo" className="accordion-collapse collapse" aria-labelledby="accTwo" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <div className="accordion-answer">
                                                    <p>In order to become a VIP, you will need to earn class points. Class points can be earned by either leveling up or purchasing our in-game products! Join our VIP Club to earn special rewards! Enjoy bonus multiples up to x7 when you become a VIP member!
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div className="accordion-header" id="accThree">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collThree" aria-expanded="true" aria-controls="collThree">
                                                <div className="accordion-list">
                                                    <div className="left">
                                                        <div className="icon"></div>
                                                    </div>
                                                    <div className="right">
                                                        <h6>03. How often are new slots added?</h6>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                        <div id="collThree" className="accordion-collapse collapse" aria-labelledby="accThree" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <div className="accordion-answer">
                                                    <p>We are introducing at least one new slot game every month in order to keep your experience with Vegas Craze Slots as fresh as possible.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div className="accordion-header" id="accFour">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collFour" aria-expanded="true" aria-controls="collFour">
                                                <div className="accordion-list">
                                                    <div className="left">
                                                        <div className="icon"></div>
                                                    </div>
                                                    <div className="right">
                                                        <h6>04. How do I cash out my winnings?</h6>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                        <div id="collFour" className="accordion-collapse collapse" aria-labelledby="accThree" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <div className="accordion-answer">
                                                    <p>Vegas Craze Slots does not provide a cash out service. All coins earned while playing our game are strictly virtual currency and can only be used in our game.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div className="accordion-header" id="accFive">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collFive" aria-expanded="true" aria-controls="collFive">
                                                <div className="accordion-list">
                                                    <div className="left">
                                                        <div className="icon"></div>
                                                    </div>
                                                    <div className="right">
                                                        <h6>05. Accidentally left game during a bonus. What happens to my coins?</h6>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                        <div id="collFive" className="accordion-collapse collapse" aria-labelledby="accThree" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <div className="accordion-answer">
                                                    <p>Don't be alarmed! Our games are designed to allow you to pick up from where you left off. It can be hard to tell at times, but if you don't gain access to your bonus game; chances are that your bonus game was on its final spin, and all winnings have already been added to your coin balance.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div className="accordion-header" id="accSix">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collSix" aria-expanded="true" aria-controls="collSix">
                                                <div className="accordion-list">
                                                    <div className="left">
                                                        <div className="icon"></div>
                                                    </div>
                                                    <div className="right">
                                                        <h6>06. How do I turn off the Broadcast feature?</h6>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                        <div id="collSix" className="accordion-collapse collapse" aria-labelledby="accThree" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <div className="accordion-answer">
                                                    <p>The Broadcast feature can not be turned off. The Broadcast feature is an important part of Vegas Craze Slots as it allows players to experience a truly social casino experience.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div className="accordion-header" id="accSeven">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collSeven" aria-expanded="true" aria-controls="collSeven">
                                                <div className="accordion-list">
                                                    <div className="left">
                                                        <div className="icon"></div>
                                                    </div>
                                                    <div className="right">
                                                        <h6>07. Reels will not stop spinning.</h6>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                        <div id="collSeven" className="accordion-collapse collapse" aria-labelledby="accThree" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <div className="accordion-answer">
                                                    <p>A non-stop spinning sequence may occur seldomly as a result of a brief disconnection between Vegas Craze Slots and your internet service provider. Our game is designed to save ALL wins, free spins, and bonus games. Simply refreshing the game will allow you to pick up where you left off.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div className="accordion-header" id="accEight">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collEight" aria-expanded="true" aria-controls="collEight">
                                                <div className="accordion-list">
                                                    <div className="left">
                                                        <div className="icon"></div>
                                                    </div>
                                                    <div className="right">
                                                        <h6>08. The game froze in the middle of a spin!</h6>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                        <div id="collEight" className="accordion-collapse collapse" aria-labelledby="accThree" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <div className="accordion-answer">
                                                    <p>Vegas Craze Slots will always save your wins and free spins, so don't hesitate to close and restart the game! Please try to remember this tip the next time your game freezes!
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div className="accordion-header" id="accNine">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collNine" aria-expanded="true" aria-controls="collNine">
                                                <div className="accordion-list">
                                                    <div className="left">
                                                        <div className="icon"></div>
                                                    </div>
                                                    <div className="right">
                                                        <h6>09. I am out of coins...</h6>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                        <div id="collNine" className="accordion-collapse collapse" aria-labelledby="accThree" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <div className="accordion-answer">
                                                    <p>If you ever run out of coins while playing the game, please make use of the available Bonus Games! Also, you can visit our Facebook Fan Page (https://www.facebook.com/VegasCraze/) to receive free coins every day! We do free coin giveaways very frequently, so please be sure to Like and Follow our Fan Page!
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div className="accordion-header" id="accTen">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collTen" aria-expanded="true" aria-controls="collTen">
                                                <div className="accordion-list">
                                                    <div className="left">
                                                        <div className="icon"></div>
                                                    </div>
                                                    <div className="right">
                                                        <h6>10. Why and how do I clear my Browser's Cache?</h6>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                        <div id="collTen" className="accordion-collapse collapse" aria-labelledby="accThree" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <div className="accordion-answer">
                                                    <p>Your browser automatically stores certain pieces of data that are used over and over again when you load into a certain site. If this initial data was downloaded while connected to an unstable connection, or there have been any errors while this download occurred, there may be remaining issues while loading our game until you clear your browser's cache and cookies. If something doesn't seem right, clearing your browser's cache and cookies may resolve the situation more often than not.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <div className="accordion-header" id="accEleven">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collEleven" aria-expanded="true" aria-controls="collEleven">
                                                <div className="accordion-list">
                                                    <div className="left">
                                                        <div className="icon"></div>
                                                    </div>
                                                    <div className="right">
                                                        <h6>11. Questions on Privacy Policy</h6>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                        <div id="collEleven" className="accordion-collapse collapse" aria-labelledby="accThree" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <div className="accordion-answer">
                                                    <p>Your data privacy and security are very important to us. If you have any specific questions regarding the following topics, please feel free to contact us at support@vegascraze.com at any time, and we will promptly return to you with answers.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Faq;